import React from "react";
// import PropTypes from "prop-types";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography, IconButton, Grid, Box } from "@mui/material";
import {
  Twitter,
  Mail,
  GitHub,
  LinkedIn,
  HelpCenter,
} from "@mui/icons-material";
import discord from "../assets/images/discord.png";

const styles = {
  footer: `position: fixed;
        background-color:#1b1c1b,
        bottom: 0;
        width: 100%;
        padding-bottom:10;
        zIndex:40;
        text-align: center;
        `,
  socialIcon: `
  margin: 10px;
  color:#fff;
        :hover: {
          color: ##51d4d8,
        };`,
  footerText: `color: #ffffff;
        lineHeight: 1;
        padding-bottom:10px`,
  discordImg: `width: 26px;
        vertical-align: middle;
        margin-left: 30px;
        cursor: pointer;
        `,
};

const iconBtnSx = {
  color: "#FFF",

};

export default function Footer(props) {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <div>
        <IconButton
          target="_blank"
          href="https://twitter.com/goplausible"
          sx={iconBtnSx}
        >
          <Twitter sx={iconBtnSx} />
        </IconButton>

        {/* <IconButton
          target="_blank"
          href="https://github.com/GoPlausible"
          sx={iconBtnSx} 
        >
          <GitHub
            sx={{iconBtnSx, marginLeft:'20px'}} 
          />
        </IconButton> */}
        <IconButton
          target="_blank"
          href="https://www.linkedin.com/company/goplausible/"
          sx={iconBtnSx}
        >
          <LinkedIn sx={{ iconBtnSx, marginLeft: "20px" }} />
        </IconButton>

        <IconButton
          target="_blank"
          href="mailto:info@goplausible.com"
          sx={iconBtnSx}
        >
          <Mail sx={{ iconBtnSx, marginLeft: "20px" }} />
        </IconButton>
        <IconButton
          target="_blank"
          href="https://docs.goplausible.xyz"
          sx={iconBtnSx}
        >
          <HelpCenter sx={{ iconBtnSx, marginLeft: "20px" }} />
        </IconButton>

        {/* <Box component='a'
          target="_blank"
          href="https://discord.com/invite/bbbUBHMV"
          rel="noopener noreferrer"
        >
          <img
            alt="discord"
            src={discord}
            css={css`
              ${styles.discordImg}
            `}
          />
        </Box> */}

        <br />

        <Typography
          variant="body1"
          css={css`
            ${styles.footerText}
          `}
        >
          ©2024 by GoPlausible
        </Typography>
      </div>
    </Grid>
  );
}
