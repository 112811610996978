import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import plaus1 from "../assets/images/plaus1.png";
import plaus2 from "../assets/images/plaus2.png";
import plaus3 from "../assets/images/plaus3.png";
import plaus4 from "../assets/images/plaus4.png";
import plaus5 from "../assets/images/plaus5.png";
import plaus6 from "../assets/images/plaus6.png";
// import plaus7 from "../assets/images/plaus7.png";
// import plaus8 from "../assets/images/plaus8.png";
import plaus9 from "../assets/images/plaus9.png";
import plaus10 from "../assets/images/plaus10.png";
// import plaus11 from "../assets/images/plaus11.png";
import plaus12 from "../assets/images/plaus12.png";

const boxSx = {
  textAlign: "left",
  width: "70%",
  margin: "auto",
};

const titleSx = {
  marginTop: "5%",
  fontFamily: "Titillium Web",
  color: "#fff",
  fontSize: { xs: "26px", sm: "35px" },
};



const imageSx={
  width: {sm:"100%",xs:'200px'},
  height: "auto",
  marginTop: "5%",
  marginLeft: {xs:'0%',sm:"6%"},
}


class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    return (
      <Grid container sx={{ backgroundColor: "#1f1f1e" }}>
        <Grid item xs={12} sm={6} md={6} order={{ xs: 2, sm: 1 }}>
        <Grid container>
        <Grid item xs={6} sm={6} md={4}>
          <Box
            component="img"
            src={plaus9}
            alt="PLAUS"
            sx={ imageSx}
          />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
          <Box
            component="img"
            src={plaus10}
            alt="PLAUS"
            sx={ imageSx}
          />
          </Grid>
          {/* <Grid item xs={6} sm={6} md={4}>
          <Box
            component="img"
            src={plaus11}
            alt="PLAUS"
            sx={ imageSx}
          />
          </Grid> */}
          <Grid item xs={6} sm={6} md={4}>
          <Box
            component="img"
            src={plaus12}
            alt="PLAUS"
            sx={ imageSx}
          />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
          <Box
            component="img"
            src={plaus1}
            alt="Consensus2023"
            sx={ imageSx}
          />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
          <Box
            component="img"
            src={plaus2}
            alt="AlgoHouse"
            sx={ imageSx}
          />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
          <Box
            component="img"
            src={plaus3}
            alt="MegaAce"
            sx={ imageSx}
          />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
          <Box
            component="img"
            src={plaus4}
            alt="GoraNework"
            sx={ imageSx}
          />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
          <Box
            component="img"
            src={plaus5}
            alt="POAF"
            sx={ imageSx}
          />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
          <Box
            component="img"
            src={plaus6}
            alt="Cryptosphere"
            sx={ imageSx}
          />
          </Grid>

          {/* <Grid item xs={6} sm={6} md={4}>
          <Box
            component="img"
            src={plaus7}
            alt="PLAUS"
            sx={ imageSx}
          />
          </Grid> */}

          {/* <Grid item xs={6} sm={6} md={4}>
          <Box
            component="img"
            src={plaus8}
            alt="PLAUS"
            sx={ imageSx}
          />
          </Grid> */}

        

        </Grid>
         
        </Grid>
        <Grid item xs={12} sm={6} md={6} order={{ xs: 1, sm: 2 }}>
          <Box component="div" sx={boxSx}>
          <Typography
              variant="h6"
              sx={{
                // marginTop: "3%",
                fontFamily: "Muli",
                fontWeight: "100",
                textAlign: "justify",
              }}
            >
              GoPlausible is the parent project building PLAUSIBLE protocol, Algorand & DID GPTs, "ThisDID.COM", "InstaMonet.com", and "dOauth.org"
           
               </Typography>
            <Typography sx={titleSx}>
              What is "PLAUSIBLE" protocol?
            </Typography>
            <br />
            <Typography
              variant="h6"
              sx={{
                marginTop: "3%",
                fontFamily: "Muli",
                fontWeight: "100",
                textAlign: "justify",
              }}
            >
              PLAUSIBLE is a W3C compliant, decentralized identifiers, verifiable credentials and smart utility NFTs protocol on Algorand
              blockchain. PLAUSIBLE protocol also supports IMS OpenBadges standard and features.
           
              <br />
              Being Token-less, dynamic
              and completely permission-less by-design and empowered by Algorand's futuristic tech stack,
              PLAUSIBLE protocol offers a robust and scalable solution for W3C compliant DIDs, VCs and utility NFTs in a simple and intuitive way!
           
              <br />
              As GoPlausible pioneers and advocates the fusion of AI technology and Algorand, a full suite of generative AI tools empowers GoPlausible service addressing PLAUS and NFT Mint operations!
            </Typography>
            <br />
          </Box>
          <br />
     
        </Grid>
      </Grid>
    );
  }
}

export default Details;
